import React from 'react';
import './About.css';

export default class Photos extends React.Component {
    render () {
        return (
            <div className="photos">
                <div className="image4">
                    <img src="./IMG_6701.JPG" alt="neuschwanstein castle, bavaria" className="image" />
                    <div className="middle">
                        <div className="text">munich, 2020</div>
                    </div>
                </div>
                <div className="img2">
                    <img src="./IMG_6753.JPG" alt="munich, germany" className="image" />
                    <div className="middle">
                        <div className="text">munich, 2020</div>
                    </div>
                </div>
                <div className="cabo">
                    <img src="./IMG_6449.JPG" alt="rose in munich botanical gardens" className="image" />
                    <div className="middle">
                        <div className="text">munich, 2020</div>
                    </div>
                </div>
                <div className="img1">
                    <img src="./prague1.JPG" className="image" alt="roses against a yellow wall" />
                    <div className="middle">
                        <div className="text">prague, 2019</div>
                    </div> 
                </div>
                <div className="image2">
                    <img src="./prague2.JPG" className="image" alt="" />
                    <div className="middle">
                        <div className="text">prague, 2019</div>
                    </div> 
                </div>
                <div className="image3">
                    <img src="./lilac.JPG" alt="lilacs" className="image" />
                    <div className="middle">
                        <div className="text">vienna, 2019</div>
                    </div>
                </div>
                <div className="all">
                    <img src="./all.JPG" alt="memorial in vienna with shadow" className="image" />
                    <div className="middle">
                        <div className="text">vienna, 2019</div>
                    </div>
                </div>
                <div className="img3">
                    <img src="./light.JPG" alt="light in the valleys of vienna" className="image" />
                    <div className="middle">
                        <div className="text">vienna, 2019</div>
                    </div>
                </div>
                <div className="vie">
                    <img src="./house.JPG" alt="yellow house obscured by trees, vienna" className="image" />
                    <div className="middle">
                        <div className="text">vienna, 2019</div>
                    </div>
                </div>
                <div className="vien">
                    <img src="./vienna.JPG" alt="building in vienna" className="image"/>
                    <div className="middle">
                        <div className="text">vienna, 2019</div>
                    </div>
                </div>
                <div className="vienn">
                    <img src="./vienna2.JPG" alt="building in vienna" className="image"/>
                    <div className="middle">
                        <div className="text">vienna, 2019</div>
                    </div>
                </div>
                <div className="cabo2">
                    <img src="./cabo1.JPG" alt="cabo, blurry at night" className="image" />
                    <div className="middle">
                        <div className="text">los cabos, 2017</div>
                    </div>
                </div>
                <div className="credit6">
                    <p>&#169; 2020 Salem Gordon. Made from scratch with React. </p>
                </div>    
            </div>
        );
    }
}