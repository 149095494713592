import React from 'react';
import './Projects.css';
import { Link} from 'react-router-dom';

export default class Amplify extends React.Component {
    render() {
        return (
            <div className="amplify">
                <div className="definition">
                    <div className="solace2">
                        <div className="high">
                            <h3 className="nAmp"><Link to="/Amplify" title="Amplify">Amplify:</Link></h3>
                            <h4 className="def">1. My six month UX Research &#38; Design internship with Adventure Biofeedback.</h4>
                            <h4 className="def2">2. A web application that allows Speech Language Pathologists to facilitate remote speech language therapy.</h4>
                            <h4 className="def3">3. To expand by the use of detail or illustration or by closer analysis.</h4>
                            <h5>See also: <Link to="/Solstice" title="Solstice">Solstice</Link></h5>
                        </div>
                    </div>
                </div>
                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                    <div className="amplify2 carousel-inner">
                        <div className="item active">
                            <Link to="/Amplify" title="Amplify">
                                <img src="./Amplify.png" alt="Amplify Webpage" className="amplifyImage" />
                            </Link>
                            <div className="middleHome">
                                <div className="textHome">
                                    <Link to="/Amplify" title="Amplify">Amplify</Link>
                                </div>
                                <div className="subText">UX Research &#38; Design</div>
                                <div className="subText2">Internship</div>
                            </div>
                        </div>
                    </div>             
                </div>
            </div>
        );
    }
}
