import React from 'react';
import './About.css';
import {Link} from 'react-router-dom';

export default class AboutPage extends React.Component {

    render () {
        return (
            <div className="aboutfull">
                <div className="about"> 
                    <main className="main">
                        <picture>
                            <source srcSet="./me.JPG" media="(max-width: 598px)" />
                            <source srcSet="./meBigger.JPG" />
                            <img src="./meBigger.JPG" alt="salem in front of an orange door" className="selfie" />
                        </picture>
                        <div className="bodyText">
                            <h1 className="myName">I'm Salem (say&#8901;luhm)</h1>
                            <div className="location">
                                <img src="./loc2.svg" alt="location pin icon" className="loc"/>
                                <h1>Seattle, WA</h1>
                            </div>
                            <p className="one">Hello! I'm a UX Designer and Researcher currently based in Seattle. Recently graduated from the University of Washington Informatics &#38; English programs in December 2020.</p>
                            <p>My passion for design is centered around telling stories and connecting with people, in order to help alleviate human problems through design &#38; technology. I'm especially driven towards projects in the impact space, which demonstrate how well-researched 
                                and user-centered products can bring about positive change. I'm also interested in content writing and editing, which allows me to exercise my interdisciplinary skills. </p>
                            <p>In my spare time, I can be found writing, traveling, taking <Link to="/gallery">photos</Link>, and pointing out nice fonts.</p>
                            <p>Don't hesitate to reach out to me by <a href="mailto:salem.gordon@gmail.com" target="_blank" rel="noopener noreferrer" title="salem.gordon@gmail.com">email</a> or on <a href="https://www.linkedin.com/in/salem-gordon/" title="Linkedin" target="_blank" rel="noopener noreferrer">Linkedin</a>!</p>
                        </div> 
                    </main>
                </div> 
                <div className="credit5">
                    <p>&#169; 2020 Salem Gordon. Made from scratch with React. </p>
                </div>    
            </div>
        );
    }
}