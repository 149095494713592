import React from 'react';
import './Amplify.css';
import {Link} from 'react-router-dom';

export default class AmplifyPage extends React.Component {

    scrollToTop(event) {
        document.documentElement.scrollTop = 0;
    }

    scrollToTime(event) {
        document.getElementById("ampc2").scrollIntoView(true);
    }

    scrollToRole(event) {
        document.getElementById("role2").scrollIntoView(true);
    }

    scrollToAmpRes(event) {
        document.getElementById("ampRes").scrollIntoView(true);
    }

    scrollToFindings(event) {
        document.getElementById("findingsA").scrollIntoView(true);
    }

    scrollToAmpFea(event) {
        document.getElementById("ampFea").scrollIntoView(true);
    }

    scrollToAmpIde(event) {
        document.getElementById("ampIde").scrollIntoView(true);
    }

    scrollToAmpReflec(event) {
        document.getElementById("ampReflec").scrollIntoView(true);
    }

    autoPlayOff2(event) {
        document.getElementById("dropdown-toggle").style.color = "#7E5DCC";
        document.getElementById("dropdown-toggle").style.fontWeight = "500";
        if (document.documentElement.clientWidth < 768) {
            document.getElementById("videoAutoA").removeAttribute("autoplay");
            document.getElementById("videoAutoA").removeAttribute("loop");
            document.getElementById("videoAutoB").removeAttribute("autoplay");
            document.getElementById("videoAutoB").removeAttribute("loop");
        } else if (document.documentElement.clientWidth > 768) {
            document.getElementById("videoAutoA").setAttribute("autoplay", true);
            document.getElementById("videoAutoA").setAttribute("loop", true);
            document.getElementById("videoAutoA").removeAttribute("controls");
        }
        if (document.documentElement.scrollTop < 10) {
            document.getElementById("backTopA").style.display = "block";
        }
    }

    render() {
        return (
            <div className="amplifyPage" onLoad={this.autoPlayOff2}>
                <div className="container1" id="titleSection">
                    <div className="titleContent2">
                        <div className="centered2">
                            <div className="titleAmp">
                                <h1>Amplify</h1>
                                <img src="./AmpLogo2.svg" alt="Amplify logo" className="logo2" />
                            </div>
                            <h2>
                                A web-based application that helps Speech Language Pathologists facilitate remote speech therapy with their patients. Enables clinicians to record audio, organize snippets, and perform analysis in real-time.
                            </h2>
                        </div>
                        <img src="./Amplify2.png" alt="Amplify prototype on PC" className="ampPic1" />
                        <img src="./Amplify2.png" alt="Amplify prototype on PC" className="ampPic2" />
                        <picture>
                            <source srcSet="./Amplify2.png" alt="Amplify prototype on PC" className="ampPic" />
                            <img src="./Amplify2.png" alt="Amplify prototype on PC" className="ampPic" />
                        </picture>
                    </div>
                    <button className="arrow2A" tabIndex="0" onClick={this.scrollToTime}>
                        <img src="./arrowblue.svg" alt="chevron down"  className="arr2A" />
                        <div className="middlethree">
                            <div className="text3">see more</div>
                        </div>
                    </button>
                </div>

                <div className="ampcontainer2" id="ampc2" onScroll={this.backTop}>
                    <div className="larger2">
                        <div className="amptimeline">
                            <div>
                                <h4>TIMELINE</h4>
                                <h5>5 months</h5>
                            </div>
                            <div className="amprole">
                                <h4>ROLE</h4>
                                <h5>(Remote) UX Research &#38; Design Intern</h5>
                            </div>
                        </div>
                        <div className="ampaffil">
                            <div className="ampaff">
                                <h4>AFFILIATION</h4>
                                <h5><a href="https://westcoastctip.org/" target="_blank" rel="noopener noreferrer">CTIP-funded</a> project</h5>
                                <h5>Internship with <a href="https://adventurebiofeedback.com/" target="_blank" rel="noopener noreferrer">Adventure Biofeedback</a></h5>
                            </div>
                            <div>
                                <h4>STATUS</h4>
                                <h5>Working prototype</h5>
                                <h5>Visible <a href="https://adventurebiofeedback.com/" target="_blank" rel="noopener noreferrer">here</a> pending further development</h5>
                            </div>
                        </div>
                    </div>
                    <button className="arrow3A" tabIndex="0" onClick={this.scrollToRole}>
                        <img src="./arrowblue.svg" alt="chevron down"  className="arr3A" />
                        <div className="middlethree">
                            <div className="text3">see more</div>
                        </div>
                    </button>
                </div>

                <div className="amproleFull" id="role2">
                    <h1>Overview</h1>
                    <div className="largeConc">
                        <div>
                            <p className="firstP">
                                In my 5-month UX Research &#38; Design internship with Adventure Biofeedback, I worked with <a href="https://carmelitogutierrez.com/" target="_blank" rel="noopener noreferrer">the other half of the design team</a> to research, iterate, and develop a functional product that would help
                                Speech Language Pathologists conduct remote speech therapy. We became involved partway through the project, and were responsible for developing the clinican side of the experience, to accompany 
                                the already existing patient-facing view.
                            </p>
                            <p>
                                The research process involved conducting seven phone interviews with local Speech Language Pathologists about their experiences with remote therapy -- including their pain points, and features they hoped to see in a potential product. 
                                In addition to phone interviews, we also conducted user testing with around five clinicians, using different prototypes of our product and iterating based on their feedback. 
                            </p>
                        </div>
                        <div>
                            <p className="firstP">
                                While there were some aspects of the product that had already been researched and established, a significant part of our role in this project was validating those feature decisions through our own research, and then working 
                                to design, develop, and iterate accordingly, based on our findings &#38; consistent feedback from both users and our supervisors.
                            </p>
                            <p>
                                After the research period, we worked to improve our existing designs to adhere to clinician responses. We presented our findings to the development &#38; management teams, and then created 
                                a working prototype using HTML, CSS, &#38; Javascript. Then we passed our code &#38; designs to the Adv. Biofeedback team so that they could develop the full product.
                            </p>
                        </div>
                    </div>
                    <button className="arrow4A" tabIndex="0" onClick={this.scrollToAmpRes}>
                        <img src="./arrowblue.svg" alt="chevron down" className="arr4A" />
                        <div className="middlethree">
                            <div className="text4">see research</div>
                        </div>
                    </button>
                </div>

                <div className="researchA" id="ampRes">
                    <h1>Research</h1>
                    <div className="topTest">
                        <div className="interviewA">
                            <h2>Interviews</h2>
                            <p>
                                We conducted around seven phone interviews with local SLPs, which allowed us to gain insight into their experiences with remote learning, typical sessions with patients, their average clientele, 
                                their transition to virtual therapy, and their primary difficulties with that process.  
                            </p>
                            <p>
                                It also provided the needed background and context for my partner and I, Aas we did not have prior experience with Speech Language Pathology. These interviews 
                                allowed us to understand the problems facing clinicians and learn the scope of their field.
                            </p>
                            <p>     
                                Based on the previous research done by our supervisors, we entered the interviews with the additional 
                                goal of validating assumptions, and seeing if the features desired by other clinicians matched the experiences of the SLPs we spoke with. 
                            </p>
                        </div>
                        <div className="interviewQuote">
                            <blockquote className="b3">
                                "Audio recordings can be valuable to track speech progress over time, especially when communicating results to parents.
                                Sometimes parents don't notice their child's progress until they hear the recordings."
                                <span>
                                    Lizbeth Parker, Seattle-area SLP
                                </span>
                            </blockquote>
                        </div>
                    </div>
                    <button className="arrow6A" tabIndex="0" onClick={this.scrollToFindings}>
                        <img src="./arrowblue.svg" alt="chevron down" className="arr6A" />
                        <div className="middlethree">
                            <div className="text4">see findings</div>
                        </div>
                    </button>
                    <div className="findingsA" id="findingsA">
                        <div className="findings1">
                            <h2>Findings</h2>
                            <h3>
                                Through our interviews, we realized that SLPs are quite diverse, with different needs, clientele, and goals for remote therapy. We determined 
                                Amplify's core features based on the most pressing clinician needs, suggestions, and pain points. 
                            </h3>
                        </div>
                        <div className="findings2">
                            <div className="circle1A" tabIndex="0">
                                <img src="./circleWatch.png" alt="circle with computer icon" className="circleWrite" />
                                <div className="takeMidA">
                                    <div className="takeTextA">
                                        <p>Keeping patients engaged over virtual/remote mediums is challenging.</p>
                                    </div>  
                                </div>
                            </div>
                            <div className="circle1A" tabIndex="0">
                                <img src="./circleAsk.png" alt="blue circle with paper icon, hover/click for text" className="circleWrite" />
                                <div className="takeMidA">
                                    <div className="takeTextA">
                                        <p>Parental involvement in-session is critical to help patients stay on-task.</p>
                                    </div>  
                                </div>
                            </div>
                            <div className="circle1A" tabIndex="0">
                                <img src="./circleTech.png" alt="circle with computer icon" className="circleWrite" />
                                <div className="takeMidA">
                                    <div className="takeTextA">
                                        <p>SLPs have a wide range of familiarity with technology.</p>
                                    </div>  
                                </div>
                            </div>   
                            <div className="circle1A" tabIndex="0">
                                <img src="./circleWrite.png" alt="blue circle with paper icon, hover/click for text" className="circleWrite" />
                                <div className="takeMidA">
                                    <div className="takeTextA">
                                        <p>Although many clinicians take notes manually, keeping everything organized can be difficult.</p>
                                    </div>  
                                </div>
                            </div>                                                 
                        </div> 
                        <button className="arrow7A" tabIndex="0" onClick={this.scrollToAmpFea}>
                            <img src="./arrowblue.svg" alt="chevron down" className="arr7A" />
                            <div className="middlethree">
                                <div className="text4">see features</div>
                            </div>
                        </button>
                    </div>                   
                </div>

                <div className="featuresA" id="ampFea">
                    <h1>Features</h1>
                    <div className="audioRec">
                        <div className="audioText">
                            <h2>Live Audio Recording</h2>
                            <p>A primary feature of Amplify is enabling clinicians to record snippets of audio in real time. Represented in the prototype by a static wave image, this is a dynamic feature 
                                that will provide a live representation of the audio. Once the recording is finished, SLPs just press the "stop" button, and the completed recording will appear in their saved 
                                snippets below. After recording, they can add notes, organize and tag utterances, or delete the recording as needed.
                            </p>
                        </div>
                        <div className="audioVid">
                            <video width="700" height="500" autoPlay="autoplay" loop muted className="videoAmp1" id="videoAutoA">
                                <source src="./AudioRecord.mp4" type="video/mp4" />
                            </video>
                            <video width="330" height="130" autoPlay="autoplay" loop muted controls className="videoAmp" id="videoAutoB">
                                <source src="./AudioRecord.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className="audioTag">
                        <div className="tagText">
                            <h2>Organizing Samples</h2>
                            <p>
                                In addition to recording audio samples, Amplify allows SLPs to tag and order the completed recordings, in order to find them later. This enables clinicians to later organize by tag, and 
                                quickly search, navigate, and find needed audio snippets. Although the tagging mechanism is optional, it is especially useful for SLPs who want to track patient progress by specific goals, or view their recordings 
                                for a particular utterance, exercise, or sound. The recording name, exercise name, and representation type also help clinicians organize patient utterances.
                            </p>
                        </div>
                        <div className="tagImage">
                           <img src="./Tagging.png" alt="tagging utterances" className="tagImage2" title="Amplify tagging screenshot"/>
                        </div>
                    </div>
                    <div className="audioNote">
                        <div className="noteText">
                            <h2>Notes</h2>
                            <p>
                                By providing an immediate notes functionality, SLPs can make comments on recordings and exercises in real-time, saving them the effort of relistening to full recordings before 
                                having to repeat the organization process. Instead, Amplify helps clinicians quickly and efficiently access multiple recordings, so they can make the most of their time with patients -- without losing 
                                vital information. To account for extended notes and comments, the notes box is resizable as needed.
                            </p>
                        </div>
                        <div className="noteImage">
                           <img src="./Notes.png" alt="notes feature" className="noteImage2" title="Amplify notes screenshot"/>
                        </div>
                    </div>
                    <button className="arrow8A" tabIndex="0" onClick={this.scrollToAmpIde}>
                        <img src="./arrowblue.svg" alt="chevron down" className="arr8A" />
                        <div className="middlethree">
                            <div className="text4">see ideation</div>
                        </div>
                    </button>
                </div>

                <div className="ideationA" id="ampIde">
                    <h1>Ideation &#38; Process</h1>
                    <div className="protoFirst">
                        <div className="protoText">
                            <h2 className="wireA">Wireframes</h2>
                            <p>
                                Before moving on to early prototypes, we created paper wireframes to generate ideas. These simple drawings allowed us to explore the types 
                                of content we needed, and how best to arrange them.
                            </p>
                        </div>
                        <div className="imgRow">
                            <img className="ampwire1" src="./wireA1.png" alt="Amplify Wireframe 1" />
                            <img className="ampwire2" src="./wireA2.png" alt="Amplify Wireframe 2" />
                        </div>
                        
                    </div>
                    <div className="protoA">
                        <div className="protoText">
                            <h2>Initial Prototypes</h2>
                            <p>
                                Based on our initial interviews with SLPs, we created our first prototypes to address their needs. We were tasked with creating a simple clinician-side screen, 
                                that implemented our core features -- allowing users to take recordings, view waveforms of audio, and make detailed notes on recordings. We also added other organizational 
                                options as well. 
                            </p>
                        </div>
                        <div className="imgRow2">
                            <img className="protoA1" src="./protoA1.png" alt="Amplify Prototype 1" />
                            <img className="protoA3" src="./protoA3.png" alt="Amplify Prototype 2" />
                        </div>
                    </div>
                    <div className="protoA2">
                        <div className="protoText">
                            <h2>User Testing</h2>
                            <p>
                                After generating our initial prototypes, we tested them with users to get feedback. This allowed us to check our UX assumptions, and gain insight into 
                                what was needed for future iterations -- in order to truly address clinician needs. 
                            </p>
                        </div>
                        <img className="protoA4" src="./protoA4.png" alt="Amplify Prototype 3" />
                    </div>
                    <div className="superProto">
                        <div className="protoText">
                            <h2>Supervisor Feedback</h2>
                            <p>
                                Following user testing &#38; subsequent revisions, we presented our prototypes to our supervisors, so that they could provide feedback. The biggest change in this iteration 
                                was moving the notes -- from the right side of the audio to below each recording, and allowing these notes to be collapsible. 
                            </p>
                        </div>
                        <img className="protoA5" src="./protoA5.png" alt="Amplify Prototype 4" />
                    </div>
                    <div className="finalDesign">
                        <div className="protoText">
                            <h2>Final Design</h2>
                            <p>
                                After receiving all the feedback from both SLPs and our supervisors, we came together to create a final design which incorporated all of the comments. The primary change 
                                here was representing the waveform at the top, rather than showing waveforms individually for each audio file, and creating more intuitive interactions.  
                            </p>
                        </div>
                        <img className="protoA6" src="./protoWork.png" alt="Amplify Working Prototype Design" />
                    </div>
                    <div className="finalProto">
                        <div className="protoText">
                            <h2>Final Prototype</h2>
                            <p>From our final design, we created a functional prototype using HTML, CSS, and Javascript. Final changes included updating button colors, icons, and overall creating cleaner and more 
                                obvious visual distinctions between sections and improving the interactions and UI.
                            </p>
                        </div>
                        <img className="workingProto" src="./WorkingProto.png" alt="Amplify Final Prototype" />
                    </div>
                    <button className="arrow5A" tabIndex="0" onClick={this.scrollToAmpReflec}>
                        <img src="./arrowblue.svg" alt="chevron down" className="arr5A" />
                        <div className="middlethree">
                            <div className="text4">
                                see more
                            </div>
                        </div>
                    </button>
                </div>

                <div className="reflectionA" id="ampReflec">
                    <h1>Reflection</h1>
                    <p>This project provided me with needed coding experience, and allowed me to experience all the steps of the design process -- including 
                        research, design, testing, and iteration. It also improved my remote teamwork skills, and highlighted the benefits and drawbacks of virtual
                        communication. Amplify was my first internship, and it reminded me of how much I enjoy working with other designers, and how the design process is so much 
                        smoother in a collaborative, supportive environment. 
                    </p>
                    <p>
                        It also taught me of how truly iterative design can (and should be), as this experience was long enough that we had time to check design assumptions, and 
                        repeatedly seek feedback. It helped me notice that what seems like small changes can be huge for user experience, and it also reinforced the idea that 
                        good UX means truly prioritizing the user, as well as validating and questioning assumptions -- in order to provide the best experience possible. This came along 
                        with the notion that designers are not the users -- and that you cannot assume that your users will be tech savvy or appreciate more complex interactions. 
                    </p>
                </div>
               

                <div className="ampFooter" id="ampFoot">
                    <div className="plink2">
                        <div className="nextP2">next project</div>
                        <div className="pName2">
                            <Link to="/Solstice" title="Solstice">Solstice<img src="./arrow-double.svg" alt="double arrow right" className="footlink2" /></Link>
                        </div>
                    </div>
                </div>
                <div className="bottomLinks2">
                    <div className="backTop3">
                        <button title="back to top" onClick={this.scrollToTop} id="backTopA"><img src="./backTop.svg" alt="back to top arrow" className="backTop2" /></button>
                    </div>
                    <div className="email3">
                        <a href="mailto:salem.gordon@gmail.com" title="Email" target="_blank" rel="noopener noreferrer">Email</a> &#8901; <a href="https://linkedin.com/in/salem-gordon" title="LinkedIn" target="_blank" rel="noopener noreferrer">LinkedIn</a> &#8901; <a href="https://drive.google.com/file/d/1OO8cBsaJbeSZPmZB-90QU7HbYqhk1zFn/view?usp=sharing" target="_blank" rel="noopener noreferrer" title="Resume">Resume</a>
                    </div>
                </div>
                <div className="credit3">
                    <p>&#169; 2020 Salem Gordon. Made from scratch with React. </p>
                </div>
            </div>   
        );
    }
    
}
