import React from 'react';
import './Projects.css';
import { Link } from 'react-router-dom';

export default class Project extends React.Component {
    render() {
        return (
            <div className="project" id="project">
                <div className="definition">
                    <div className="solace2" id="solace2">
                        <div className="high">
                            <h3 className="nSol"><Link to="/Solace" title="Solace">Sol&#8203;ace:</Link></h3>
                            <h4 className="def">1. My culminating Capstone project within the University of Washington Informatics program. </h4>
                            <h4 className="def2">2. An iOS mobile app created to provide resources to those experiencing domestic violence, especially during the COVID-19 pandemic.</h4>
                            <h4 className="def3">3. A source of relief or consolation.</h4>
                            <h5>See also: <Link to="/Amplify" title="Amplify">Amplify</Link>, <Link to="/Solstice" title="Solstice">Solstice</Link></h5>
                        </div>
                    </div>
                </div>
                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                    <div className="solace carousel-inner">
                        <div className="item active">
                            <Link to="/Solace" title="Solace">
                                <img src="./SolaceUpdate.png" alt="Solace home screen on tilted iPhone 6 with green line wrapped around it" className="solaceImage" />
                            </Link>
                            <div className="middleHome">
                                <div className="textHome"><Link to="/Solace" title="Solace">Solace</Link></div>
                                <div className="subText">Product Lead</div>
                                <div className="subText2">UX Research &#38; Design</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
