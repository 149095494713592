import React from 'react';
import './Projects.css';
import { Link} from 'react-router-dom';

export default class Solstice extends React.Component {
    render() {
        return (
            <div className="solstice">
                <div className="definition">
                    <div className="solace2">
                        <div className="high">
                            <h3 className="nSol2"><Link to="/Solstice" title="Solstice">Solstice:</Link></h3>
                            <h4 className="def">1. A mobile Operating System design concept centered around balance and function. </h4>
                            <h4 className="def2">2. My UX Design &#38; Research project for INFO 365 at the University of Washington. </h4>
                            <h4 className="def3">3. The moment when the sun reaches its northernmost or southernmost point from Earth’s equator.</h4>
                            <h5>See also: <Link to="/Solace" title="Solace">Solace</Link>,<Link to="/Amplify" title="Amplify"> Amplify</Link></h5>
                        </div>
                    </div>
                </div>
                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                    <div className="solstice2 carousel-inner">
                        <div className="item active">
                            <Link to="/Solstice" title="Solstice"><img src="./Solstice.png" alt="Solstice Screens" className="solsticeImage" /></Link>
                            <div className="middleHome">
                                <div className="textHome"><Link to="/Solstice">Solstice</Link></div>
                                <div className="subText">UX Design</div>
                            </div>
                        </div>
                    </div>             
                </div>
            </div>
        );
    }
}