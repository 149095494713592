import React from 'react';
import './App.css';
import Nav from './Nav.js';
import AboutPage from './About.js';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Main from './Main.js';
import Photos from './Photos.js';
import SolacePage from './SolacePage.js';
import AmplifyPage from './AmplifyPage.js';
import SolsticePage from './SolsticePage.js';
import ScrollToTop from './ScrollToTop.js';

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className="body">
          <Nav />
          <Switch>
            <Route path="/about" component={AboutPage} />
            <Route path="/gallery" component={Photos} />
            <Route exact path="/" component={Main} />
            <Route path="/Solace" component={SolacePage} />
            <Route path="/Amplify" component={AmplifyPage} />
            <Route path="/Solstice" component={SolsticePage} />
          </Switch>
        </div>
      </Router>  
    );
  }
}
