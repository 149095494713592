import React from 'react';
import './App.css';
import Project from './Projects.js';
import Amplify from './Amplify.js';
import Solstice from './Solstice.js';
import Footer from './Footer.js';
import Intro from './Intro.js';

export default class Main extends React.Component {
    render () {
        return (
            <main>
                <Intro />
                <Project />
                <Amplify />
                <Solstice />
                <Footer />
            </main>
        );
    }
}