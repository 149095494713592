import React from 'react';
import './Solace.css';
import { Link } from 'react-router-dom';

export default class SolacePage extends React.Component {

    scrollToTop(event) {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }

    scrollToThree(event) {
        document.getElementById("intro").scrollIntoView(true);
    }

    scrollToFour(event) {
        document.getElementById("concept").scrollIntoView(true);
    }

    scrollToFive(event) {
        document.getElementById("quote").scrollIntoView(true);
    }

    scrollToFeat(event) {
        document.getElementById("features").scrollIntoView(true);
    }

    scrollToSix(event) {
        document.getElementById("video1").scrollIntoView(true);
    }

    scrollToSeven(event) {
        document.getElementById("safescreen").scrollIntoView(true);
    }

    scrollTo8(event) {
        document.getElementById("video2").scrollIntoView(true);
    }

    scrollToShel(event) {
        document.getElementById("shelters").scrollIntoView(true);
    }

    scrollTo9(event) {
        document.getElementById("video").scrollIntoView(true);
    }

    scrollToMessage(event) {
        document.getElementById("message").scrollIntoView(true);
    }

    scrollTo10(event) {
        document.getElementById("messVid").scrollIntoView(true);
    }

    scrollToSafety(event) {
        document.getElementById("safety").scrollIntoView(true);
    }

    scrollTo11(event) {
        document.getElementById("safeVid").scrollIntoView(true);
    }

    scrollToResearch(event) {
        document.getElementById("research").scrollIntoView(true);
    }
    
    scrollToInterview(event) {
        document.getElementById("interview").scrollIntoView(true);
    }

    scrollToState(event) {
        document.getElementById("statement").scrollIntoView(true);
    }

    scrollToDesign(event) {
        document.getElementById("designC").scrollIntoView(true);
    }

    scrollToIdea(event) {
        document.getElementById("idea").scrollIntoView(true);
    }

    scrollToProto(event) {
        document.getElementById("proto").scrollIntoView(true);
    }

    scrollToWireframes(event) {
        document.getElementById("wireframes").scrollIntoView(true);
    }

    scrollToIA(event) {
        document.getElementById("IAFull").scrollIntoView(true);
    }

    scrollToReflec(event) {
        document.getElementById("reflec").scrollIntoView(true);
    }

    scrollFunction(event) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ) {
            document.getElementById("backTopB").style.display = "block";
        } else {
            document.getElementById("backTopB").style.display= "none";
        }
    }

    autoPlayOff(event) {
        document.getElementById("dropdown-toggle").style.color = "#7E5DCC";
        document.getElementById("dropdown-toggle").style.fontWeight = "500";
        if (document.documentElement.clientWidth < 768) {
            document.getElementById("videoAuto").removeAttribute("autoplay");
            document.getElementById("videoAuto1").removeAttribute("autoplay");
            document.getElementById("videoAuto2").removeAttribute("autoplay");
            document.getElementById("videoAuto3").removeAttribute("autoplay");
            document.getElementById("videoAuto4").removeAttribute("autoplay");
            document.getElementById("videoAutoM").removeAttribute("autoplay");
            document.getElementById("videoAutoN").removeAttribute("autoplay");
            document.getElementById("videoAuto").removeAttribute("loop");
            document.getElementById("videoAuto1").removeAttribute("loop");
            document.getElementById("videoAuto2").removeAttribute("loop");
            document.getElementById("videoAuto3").removeAttribute("loop");
            document.getElementById("videoAuto4").removeAttribute("loop");
            document.getElementById("videoAutoM").removeAttribute("autoplay");
            document.getElementById("videoAutoN").removeAttribute("autoplay");
        } else if (document.documentElement.clientWidth > 768) {
            document.getElementById("videoAuto").setAttribute("autoplay", true);
            document.getElementById("videoAuto1").setAttribute("autoplay", true);
            document.getElementById("videoAuto2").setAttribute("autoplay", true);
            document.getElementById("videoAuto3").setAttribute("autoplay", true);
            document.getElementById("videoAuto4").setAttribute("autoplay", true);
            document.getElementById("videoAutoM").setAttribute("autoplay", true);
            document.getElementById("videoAutoN").setAttribute("autoplay", true);
            document.getElementById("videoAuto").setAttribute("loop", true);
            document.getElementById("videoAuto1").setAttribute("loop", true);
            document.getElementById("videoAuto2").setAttribute("loop", true);
            document.getElementById("videoAuto3").setAttribute("loop", true);
            document.getElementById("videoAuto4").setAttribute("loop", true);
            document.getElementById("videoAutoM").setAttribute("loop", true);
            document.getElementById("videoAutoN").setAttribute("loop", true);
            document.getElementById("videoAuto").removeAttribute("controls");
            document.getElementById("videoAuto1").removeAttribute("controls");
            document.getElementById("videoAuto2").removeAttribute("controls");
            document.getElementById("videoAuto3").removeAttribute("controls");
            document.getElementById("videoAuto4").removeAttribute("controls");
        }
    }
    
    render() {
        return (
            <div className="mainPage" onScroll={this.scrollFunction} onLoad={this.autoPlayOff}>
                <div className="container2" id="firstContainer">
                    <div className="titleContent">
                        <div className="centered">
                            <div className="titleSol">
                                <h1>Solace</h1>
                                <img src="./SolaceLogo.png" alt="Solace logo" className="logo" />
                                <img src="./testflight.png" className="test" alt="Testflight logo" />
                            </div>
                            <h2>
                                A free iOS mobile app that provides resources, information, and 
                                support to those experiencing domestic violence, especially during the COVID-19 pandemic.
                            </h2>
                        </div>
                        <img src="./SolaceWrapX2.png" alt="Solace home screen on iPhone 6" className="solaceHome" />
                        <img src="./solaceWrap3.png" alt="Solace home screen on iPhone 6" className="solaceHome2" />
                        <picture>
                            <source srcSet="./plain.png" media="(max-width: 768px)" />
                            <source srcSet="./SolaceLarge.png" alt="Solace home screen on iPhone 6 gold" className="solaceH" />
                            <img src="./SolaceLarge.png" alt="Solace home screen on iPhone 6" className="solaceH" />
                        </picture>
                    </div>
                    <button className="arrow2" tabIndex="0" onClick={this.scrollToThree}>
                        <img src="./arrowpurp.svg"  alt="chevron down to next section" className="arr2" />
                        <div className="middlethree">
                            <div className="text3">see more</div>
                        </div>
                    </button>
                </div>
                <div className="container3" id="intro">
                    <div className="larger">
                        <div className="timeline">
                            <div>
                                <h4>TIMELINE</h4>
                                <h5>3 months</h5>
                            </div>
                            <div className="role">
                                <h4>ROLE</h4>
                                <h5>Product Lead</h5>
                                <h5>UX Research &#38; Design</h5>
                            </div>
                        </div>
                        <div className="affil">
                            <div className="aff">
                                <h4>AFFILIATION</h4>
                                <h5>Capstone Project for the UW Informatics program</h5>
                            </div>
                            <div>
                                <h4>STATUS</h4>
                                <h5>Beta app in iOS App Store</h5>
                            </div>
                        </div>
                    </div>
                    <button className="arrow3" tabIndex="0" onClick={this.scrollToFour}>
                        <img src="./arrowpurp.svg" alt="purple chevron down"  className="arr3" />
                        <div className="middle4">
                            <div className="textfour">
                                see concept
                            </div>
                        </div>
                    </button>
                </div>
                

                <div className="container4" id="concept">
                    <div className="smallerCont">
                        <div className="textSide">
                            <h1>Concept</h1>    
                            <p className="firstP">
                                Solace began in the midst of the Coronavirus pandemic, as reports were coming in globally about the increase in domestic violence incidence and severity, coupled
                                in many locations with a decrease in calls to domestic violence hotlines. 
                            </p>
                            <p>
                                Widespread stay-at-home mandates meant that many people were quarantined with abusive partners, cut off from access to typical support structures. Under these conditions, reporting abuse and seeking help became even more difficult.  
                            </p>
                            <p>
                                Driven by our passion to make an impact, especially within a crisis that concerned information access, 
                                my team created Solace to provide support and resources. 
                            </p>
                        </div>
                        <button className="arrow4" tabIndex="0" onClick={this.scrollToFive}>
                            <img src="./arrowpurp.svg"  alt="purple chevron down" className="arr4"/>
                            <div className="middle5">
                                <div className="text5">
                                    see more
                                </div>
                            </div>
                        </button>
                        <div className="quote" id="quote">
                            <blockquote className="b1">
                                "[According to] the chief executive officer of the National Domestic Violence Hotline, 
                                there had actually been fewer calls in recent days ... 
                                <b>This is not because domestic violence is happening less, but because it is harder for victims to report it.</b>"
                                <span>
                                    <a href="https://www.nytimes.com/2020/03/23/opinion/covid-domestic-violence.html" target="_blank" rel="noopener noreferrer" className="article" title="New York Times">Tayna Selvaratnam, <i>New York Times</i></a>
                                </span>
                            </blockquote> 
                        </div>
                    </div>
                    
                    <button className="arrowQuote" tabIndex="0" onClick={this.scrollToFeat}>
                        <img src="./arrowpurp.svg"  alt="purple chevron down to features" className="arrQuote" />
                        <div className="middleQuote">
                            <div className="textQuote">
                                see features
                            </div>
                        </div>
                    </button>
                </div>

                <div className="features" id="features">
                    <h1 className="fea">Features</h1>
                    <div className="videos">
                        <div className="onboarding"> 
                            <div className="on">
                                <button className="arrow52" tabIndex="0" onClick={this.scrollToSeven}>
                                    <img src="./arrowpurp.svg"  alt="chevron down to safescreen feature" className="arr52" />
                                    <div className="middle62">
                                        <div className="text62">
                                            next feature
                                        </div>
                                    </div>
                                </button> 
                                <div className="video1" id="video1">
                                    <video width="290" height="500" className="smallOn">
                                        <source src="./Onboarding2.mp4" type="video/mp4" />
                                    </video>
                                    <video width="360" height="500" autoPlay="autoplay" loop controls muted poster="./Homebezel.png" className="normOn" id="videoAuto">
                                        <source src="./Onboarding2.mp4" type="video/mp4" />
                                    </video>
                                    <p className="onboardP">Onboarding Flow</p> 
                                </div>
                                <div className="photoOne">
                                    <img src="./Onboarding1.png" alt="Onboarding screen" className="onboard"/>
                                    <p className="onboardP2">Welcome screen</p>
                                </div>
                                <button className="arrowVid" tabIndex="0" onClick={this.scrollToSix}>
                                    <img src="./arrowpurp.svg"  alt="purple chevron down" className="arrVid" />
                                    <div className="middleVid">
                                        <div className="textVid">
                                            see more
                                        </div>
                                    </div>
                                </button>
                                <div className="onP">
                                    <h2>Onboarding</h2>
                                    <p>
                                        In order to give users the quickest access to Solace, we provided a short (and optional) onboarding flow that consists of two questions. Users can select their level of password protection, 
                                        and choose an initial emergency contact. 
                                    </p>    
                                    <p>
                                        Users are then shown the location of both the safe screen and the emergency contact button. We wanted to offer the most simplified and efficient onboarding model that would allow users to quickly access necessary resources.
                                    </p>
                                    <button className="arrow5" tabIndex="0" onClick={this.scrollToSeven}>
                                        <img src="./arrowpurp.svg"  alt="purple chevron down" className="arr5" />
                                        <div className="middle6">
                                            <div className="text6">
                                                next feature
                                            </div>
                                        </div>
                                    </button>   
                                </div>
                                  
                            </div>
                        </div>

                        <div className="safescreen" id="safescreen">
                            <div className="safe">
                                <div className="safeP">
                                    <h2>Safe Screen</h2>
                                    <p>
                                        Solace's "safe screen", enabled globally through the lock icon in the top righthand corner of all screens, is designed to protect user
                                        privacy by covering the Solace app with another screen that disguises Solace's purpose. 
                                    </p>
                                    <p>
                                        The default safe screen is a calculator, which can only be closed by typing "3x1+", which is meant to symbolize the word "exit". This closing code is provided to the user when they first use 
                                        Solace. Alternate safe screens are available in settings.
                                    </p>
                                    <button className="arrow62" tabIndex="0" onClick={this.scrollToShel}>
                                        <img src="./arrowpurp.svg" alt="purple chevron down" className="arr62" />
                                        <div className="middle72">
                                            <div className="text72">
                                                next feature
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <button className="arrowVid2" tabIndex="0" onClick={this.scrollTo8}>
                                    <img src="./arrowpurp.svg" alt="purple chevron down"  className="arrVid2" />
                                    <div className="middleVid2">
                                        <div className="textVid2">
                                            see more
                                        </div>
                                    </div>
                                </button>
                                <div className="video2" id="video2">
                                    <video width="290" height="500" className="smallSafe2">
                                        <source src="./SafeScreen.mp4" type="video/mp4" muted />
                                    </video>
                                    <video width="360" height="500" autoPlay="autoplay" loop controls poster="./Homebezel.png" muted className="normSafe2" id="videoAuto1">
                                        <source src="./SafeScreen.mp4" type="video/mp4" />
                                    </video>
                                    <p className="safescreen1">Safe Screen Flow</p>
                                </div>
                                <div className="photo2">
                                    <img src="./Calculator.png" alt="Calculator safe screen" />
                                    <p className="safescreentwo">Calculator Safe Screen</p>
                                </div>
                            </div>
                            <button className="arrow6" tabIndex="0" onClick={this.scrollToShel}>
                                <img src="./arrowpurp.svg" alt="purple chevron down" className="arr6" />
                                <div className="middle7">
                                    <div className="text7">
                                        next feature
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="shelters" id="shelters">
                            <div className="shel">
                                <button className="arrow72" tabIndex="0" onClick={this.scrollToMessage}>
                                    <img src="./arrowpurp.svg" alt="purple chevron down" className="arr72" />
                                    <div className="middle82">
                                        <div className="text82">
                                            next feature
                                        </div>
                                    </div>
                                </button>
                                <div className="video" id="video">
                                    <video width="290" height="500" className="smallShel" muted>
                                        <source src="./Shelters3.mp4" type="video/mp4" />
                                    </video>
                                    <video width="360" height="500" autoPlay="autoplay" loop controls muted poster="./Homebezel.png" className="normShel" id="videoAuto2">
                                        <source src="./Shelters3.mp4" type="video/mp4" />
                                    </video>
                                    <p className="sheltervid">Shelters &#38; Hotlines Flow</p>
                                </div>
                                <div className="photo1">
                                    <img src="./HotlineThree.png" alt="Hotlines home screen" className="shelter" />
                                    <p className="hotlineone">National Hotlines</p>
                                </div>
                                <button className="arrowVid3" tabIndex="0" onClick={this.scrollTo9}>
                                    <img src="./arrowpurp.svg" alt="purple chevron down" className="arrVid3" />
                                    <div className="middleVid3">
                                        <div className="textVid3">
                                            see more
                                        </div>
                                    </div>
                                </button>
                                <div className="shelP">
                                    <h2>Shelters &#38; Hotlines</h2>
                                    <p>
                                        With the shelters and hotlines feature, users can identify local resources and view information about shelters and hotlines in-app. All links
                                        open with an in-app browser window, which protects users who may have their browsing history monitored. 
                                    </p>
                                    <p>
                                        Each shelter and hotline includes contact information, a link to a website with more information, and the hours each resource is available. The resources can also be contacted directly through Solace, and can be added
                                        to the users' Solace contact list.   
                                    </p>
                                    <button className="arrow7" tabIndex="0" onClick={this.scrollToMessage}>
                                        <img src="./arrowpurp.svg" alt="purple chevron down" className="arr7" />
                                        <div className="middle8">
                                            <div className="text8">
                                                next feature
                                            </div>
                                        </div>
                                    </button>   
                                </div>
                                                            
                            </div>
                            
                        </div>
                        
                        <div className="message" id="message">
                            <div className="mess">
                                <div className="messP">
                                    <h2>In-App Message &#38; Call</h2>
                                    <p>
                                        Solace offers in-app messaging, as well as in-app outgoing calls that do not show up in the users' call log. Users can call contacts, 
                                        hotlines, and shelters for up to 10 minutes through a designated Solace number. Future versions of Solace will allow users to 
                                        send their location to trusted contacts. 
                                    </p>
                                    <p>
                                        Messages are deleted as a default to protect user safety, but there is an option to save individual 
                                        messages or turn off "auto-hide" in settings. Users can invite contacts to join Solace through this in-app messaging, which provides them a code
                                        to access the conversation. 
                                    </p>
                                    <button className="arrow82" tabIndex="0" onClick={this.scrollToSafety}>
                                        <img src="./arrowpurp.svg" alt="purple chevron down" className="arr82" />
                                        <div className="middle92">
                                            <div className="text92">
                                                next feature
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <button className="arrowVid4" tabIndex="0" onClick={this.scrollTo10}>
                                    <img src="./arrowpurp.svg" alt="purple chevron down" className="arrVid4" />
                                    <div className="middleVid4">
                                        <div className="textVid4">
                                            see more
                                        </div>
                                    </div>
                                </button>
                                <div className="messVid" id="messVid">
                                    <video width="290" height="500" autoPlay="autoplay" loop id="videoAuto3" controls muted poster="./Homebezel.png">
                                        <source src="./ChatFlow2.mp4" type="video/mp4" />
                                    </video>
                                    <p className="chatvid">Chat Flow</p>
                                </div>
                                <div className="callVid">
                                    <video width="290" height="500" autoPlay="autoplay" loop id="videoAutoM" muted>
                                        <source src="./Call.mp4" type="video/mp4" />
                                    </video>
                                    <p className="callvid">Calling Flow</p>
                                </div>
                            </div>
                            <button className="arrow8" tabIndex="0" onClick={this.scrollToSafety}>
                                <img src="./arrowpurp.svg" alt="purple chevron down" className="arr8" />
                                <div className="middle9">
                                    <div className="text9">
                                        next feature
                                    </div>
                                </div>
                            </button>
                        </div>
                        
                        <div className="safety" id="safety">
                            <div className="safe2">
                                <button className="arrow92" tabIndex="0" onClick={this.scrollToResearch}>
                                    <img src="./arrowpurp.svg" alt="purple chevron down" className="arr92" />
                                    <div className="middle102">
                                        <div className="text102">
                                            see research
                                        </div>
                                    </div>
                                </button>
                                <div className="safeVid" id="safeVid">
                                    <video width="290" height="500" className="smallSafe" muted>
                                        <source src="./SafetyPlan.mp4" type="video/mp4" />
                                    </video>
                                    <video width="360" height="500" autoPlay="autoplay" loop controls className="normSafe" id="videoAuto4" poster="./Homebezel.png" muted>
                                        <source src="./SafetyPlan.mp4" type="video/mp4" />
                                    </video>
                                    <p className="safeP2">Safety Plan Flow</p>
                                </div>
                                <div className="planVid">
                                    <video width="250" height="500" autoPlay="autoplay" loop id="videoAutoN">
                                        <source src="./PlanResource.mp4" type="video/mp4" />
                                    </video>
                                    <p className="planP">Safety Plan Resource Flow</p>
                                </div>
                                <button className="arrowVid5" tabIndex="0" onClick={this.scrollTo11}>
                                    <img src="./arrowpurp.svg" alt="purple chevron down" className="arrVid5" />
                                    <div className="middleVid5">
                                        <div className="textVid5">
                                            see more
                                        </div>
                                    </div>
                                </button>
                                <div className="safetyP">
                                    <h2>Safety Planning</h2>
                                    <p>
                                        Solace users can create individual safety plans to outline what safety means for them, assisted by resources sourced from 
                                        the <a href="https://thehotline.org" title="National Domestic Violence Hotline" target="_blank" rel="noopener noreferrer">National Domestic Violence Hotline</a>. Users can write plans, lock and password-protect plans, and send plans to trusted contacts
                                        in-app.  
                                    </p>
                                    <p>
                                        The resources provided explain how to make personalized safety plan that is specific to each user's needs, whether they plan to stay in or leave their relationship. Future versions of Solace will provide a more comprehensive
                                        in-app safety plan questionnaire. 
                                    </p>
                                    <button className="arrow9" tabIndex="0" onClick={this.scrollToResearch}>
                                        <img src="./arrowpurp.svg" alt="purple chevron down" className="arr9" />
                                        <div className="middle10">
                                            <div className="text10">
                                                see research
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="container5" id="research">
                    <h1>Research</h1>
                    <div className="research">
                        <p className="int">
                            As Product Lead, I was responsible for creating the product vision, and advocating for user needs. This included conducting all research, in order to understand the scope of the problem space. Much of our research occurred alongside design work, which included competitive analysis of existing mobile and web platforms geared towards victims of domestic violence, 
                            reading up on resources circulated by advocates and experts, interviewing key stakeholders, and conducting usability testing. 
                        </p>
                        <p className="int">
                            Due to the COVID-19 pandemic and the accelerated timeline of our project, it was difficult to get in contact with users. However, utilizing the semi-structured interviews and usability tests we were able to hold, along with my extensive 
                            research of existing solutions and resources, I was able to ideate on core features, and validate our findings regarding user pain points, use cases, and key interactions.
                        </p>
                        <p className="int">
                            Recognizing the impacts of quarantine mandates, Solace started as an exclusively mobile project, as home computers were more likely to be monitored, accessed, or shared
                            by abusive partners. There were also many credible resources already available on web platforms for domestic violence survivors, such as the National Domestic Violence Hotline, as well
                            as local advocacy organizations.  
                        </p>
                    </div>
                    <button className="arrowRes" tabIndex="0" onClick={this.scrollToInterview}>
                        <img src="./arrowpurp.svg" alt="purple chevron down" className="arrRes" />
                        <div className="middleRes">
                            <div className="textRes">
                                see findings
                            </div>
                        </div>
                    </button>
                    
                    <div className="interview" id="interview">
                        <h2>Key research findings</h2>
                        <h3 className="resSub">From our interviews with survivors and advocates, along with our competitive analysis of apps, websites, and resources tailored towards this problem space, we discovered... </h3>
                        <div className="takeaways">
                            <div className="circle1" tabIndex="0">
                                <img src="./circle11.png" alt="circle with chat icon" className="takeaway1"/>
                                <div className="takeMid">
                                    <div className="takeText">
                                        <p>Users want to find resources without judgement or demands</p>
                                    </div>  
                                </div>
                            </div>
                            <div className="circle1" tabIndex="0">
                                <img src="./circle8.png" alt="blue circle with iphone icon" className="takeaway1" />
                                <div className="takeMid">
                                    <div className="takeText">
                                        <p>Survivors worry about their partners viewing their devices</p>
                                    </div>  
                                </div>
                            </div>
                            <div className="circle1" tabIndex="0">
                                <img src="./circle7.png" alt="circle with computer icon" className="takeaway1" />
                                <div className="takeMid">
                                    <div className="takeText">
                                        <p>Not all users have secure accounts to access other resources</p>
                                    </div>  
                                </div>
                            </div>
                            <div className="circle1" tabIndex="0">
                                <img src="./circle10.png" alt="circle with link icon" className="takeaway1" />
                                <div className="takeMid">
                                    <div className="takeText">
                                        <p>To protect users, all links &#38; features should remain within the app</p>
                                    </div>  
                                </div>
                            </div>
                            <div className="circle1" tabIndex="0">
                                <img src="./circle9.png" alt="circle with clock icon" className="takeaway1" />
                                <div className="takeMid">
                                    <div className="takeText">
                                        <p>Users are frustrated with platforms that limit or delay full app functionality</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <button className="arrowRes2" tabIndex="0" onClick={this.scrollToState}>
                            <img src="./arrowpurp.svg" alt="purple chevron down" className="arrRes2" />
                            <div className="middleRes2">
                                <div className="textRes2">
                                    see problem statement
                                </div>
                            </div>
                        </button>

                        <div className="statement" id="statement">
                            <h2>Problem statement</h2>
                            <div className="fullProblem">
                                <div className="problem" id="problem">
                                    <h3>Based on our research findings, we created a problem statement that identified the core needs of our users. </h3>
                                    <div className="quote2">
                                        <blockquote className="b2">                        
                                            People experiencing domestic violence who are quarantined with abusive partners need a way to <b>quickly 
                                            access comprehensive resources</b> in one location <b>without compromising their safety or privacy.</b>
                                        </blockquote> 
                                    </div>
                                </div>
                                <button className="arrowDes" tabIndex="0" onClick={this.scrollToDesign}>
                                    <img src="./arrowpurp.svg" alt="purple chevron down" className="arrDes" />
                                    <div className="middleDes">
                                        <div className="textDes">
                                            see more
                                        </div>
                                    </div>
                                </button>
                                <div className="rectangle"></div>
                                <div className="designC" id="designC">
                                    <h3>We outlined key design requirements to address the problem statement:</h3>
                                    <h4><b>1.</b> Provide easy access to comprehensive and accurate resources about domestic violence.</h4>
                                    <h4><b>2.</b> Offer a quick exit or "cloaking" path to protect survivors who need to disguise Solace's purpose.</h4>
                                    <h4><b>3.</b> Allow users to connect with support systems, both personal and professional, within the safety of the app.</h4>
                                    <h4><b>4.</b> Help users create personalized safety plans for their unique situation.</h4>
                                </div> 
                            </div>
                            <button className="arrowRes3" tabIndex="0" onClick={this.scrollToIdea}>
                                <img src="./arrowpurp.svg" alt="purple chevron down" className="arrRes3" />
                                <div className="middleRes3">
                                    <div className="textRes3"> 
                                        see ideation
                                    </div>
                                </div>
                            </button>
                        </div>                       
                    </div>
                </div>

                <div className="ideation" id="idea">
                    <div className="wireframeFull">
                        <div className="ideateIntro">
                            <h1>Ideation</h1>
                            <p className="ideate">After the initial research process, we started designing wireframes that allowed us to ideate on core features and interactions. The structure of Solace changed over the course of 
                                the design process, as we gained more information from our users, conducted further research, and held usability tests. We also outlined the flow of core features and screens through an information architecture diagram. The wireframes and prototype
                                shown depict our final wireframe iteration before we started creating high-fidelity screens.
                            </p>
                        </div>
                        <div className="interact">
                            <p className="interAct1">tap to interact with prototype</p>
                            <p className="interAct2">or view key screens below</p>
                            <img src="./protoArrow2.png" alt="green arrow down" className="protoArrow2" title="tap for wireframes"onClick={this.scrollToWireframes}/>
                        </div>
                        <div className="interArrow">
                            <img src="./protoArrow.png" alt="green arrow right pointing to prototype" className="protoArrow" />
                        </div>
                        <button className="arrowPro" tabIndex="0" onClick={this.scrollToProto}>
                            <img src="./arrowpurp.svg" alt="chevron down" className="arrPro" />
                            <div className="middlePro">
                                <div className="textPro">
                                    see prototype
                                </div>
                            </div>
                        </button>
                        <div className="proto" id="proto">
                            <div className="protoFull">
                                <img src="./topfull3.png" alt="device prototype" className="topFull" />
                                <img src="./left3.png" alt="device prototype" className="leftFull" />
                                <img src="./right3.png" alt="device prototype" className="rightFull" />
                                <img src="./bottomfull3.png" alt="device prototype" className="bottomFull" />
                                <img src="./topfull2.png" alt="device prototype frame" className="topFull2" />
                                <img src="./left2.png" alt="device prototype frame" className="leftFull2" />
                                <img src="./right2.png" alt="device prototype frame" className="rightFull2" />
                                <img src="./bottomfull2.png" alt="device prototype frame" className="bottomFull2" />
                            </div>
                            <div className="iframe">
                                <iframe width="260" height="563" src="https://xd.adobe.com/embed/40237376-ab9d-4a57-845a-716160f94bb5-1570/" frameBorder="0" title="prototype" id="iframe1" alt="interactive prototype"></iframe>
                            </div>
                            <div className="iframe2">
                                <iframe width="250" height="553" src="https://xd.adobe.com/embed/40237376-ab9d-4a57-845a-716160f94bb5-1570/" frameBorder="0" title="prototype" id="iframe2" alt="interactive prototype"></iframe>
                            </div>
                            <div className="iframe3">
                                <iframe width="230" height="533" src="https://xd.adobe.com/embed/40237376-ab9d-4a57-845a-716160f94bb5-1570/" frameBorder="0" title="prototype" id="iframe3" alt="interactive prototype"></iframe>
                            </div>
                        </div>
                    </div>
                    <button className="arrowWire" tabIndex="0" onClick={this.scrollToWireframes}>
                        <img src="./arrowpurp.svg" alt="purple chevron down" className="arrWire" />
                        <div className="middleWire">
                            <div className="textWire">
                                see wireframes
                            </div>
                        </div>
                    </button>

                    <div className="wireframes" id="wireframes">
                        <h2>Wireframes</h2>
                        <h3 className="invite">Invite Contact Flow</h3>
                        <div className="wire">
                            <div className="row1">
                                <div className="cont1">
                                    <img src="./ContactsWireNS.png" alt="Solace Contacts Home Wireframe" className="contwire" />
                                    <p>Contacts Home</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong" />
                                <div className="cont2">
                                    <img src="./ContactsWire2.png" alt="Add Contact Wireframe" className="contwire2" />
                                    <p>Add Contact</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong3" />
                                <div className="cont3">
                                    <img src="./ContactsWire4.png" alt="Send Invite Request Wireframe" className="contwire4" />
                                    <p>Send Invite Request</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong" />
                                <div className="cont4">
                                    <img src="./ContactsWire7.png" alt="Invite Contact Draft Wireframe" className="contwire6" />
                                    <p>Invite Draft</p>
                                </div>
                            </div>
                            <h3>Shelters &#38; Hotlines Flow</h3>
                            <div className="row2">
                                <div className="shel1">
                                    <img src="./ShelterHome.png" alt="Solace Shelter Wireframe" className="shelwire1" />
                                    <p>Shelters Home</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong2" />
                                <div className="shel2">
                                    <img src="./Shelter2.png" alt="Solace Shelter Wireframe 2" className="shelwire2" />
                                    <p>Shelters Call Screen</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong3" />
                                <div className="hot1">
                                    <img src="./Hotline1.png" alt="Solace Hotline Home" className="hotwire1" />
                                    <p>Hotlines Home</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong2" />
                                <div className="hot2">
                                    <img src="./Hotline2.png" alt="Solace Add Hotline Wireframe" className="hotwire2" />
                                    <p>Add Hotline</p>
                                </div>
                            </div>
                            <h3 className="planning">Safety Planning Flow</h3>
                            <div className="row3">
                                <div className="safe1">
                                    <img src="./SafetyHome.png" alt="Plan Resources Wireframe" className="safewire1" />
                                    <p>Plan Resources</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong2" />
                                <div className="safety2">
                                    <img src="./SafetyPlan2.png" alt="Resource Wireframe" className="safewire2" />
                                    <p>Plan Resource</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong3" />
                                <div className="safety3">
                                    <img src="./SafetyPlan1.png" alt="Safety Plan Wireframe" className="safewire3" />
                                    <p>My Safety Plan</p>
                                </div>
                                <img src="./arrowlong5.svg" alt="long arrow right" className="arrowLong2" />
                                <div className="safety4">
                                    <img src="./SafetyPlan3.png" alt="Edit Safety Plan Wireframe" className="safewire4" />
                                    <p>Edit Safety Plan</p>
                                </div>
                            </div>
                        </div>
                        <button className="arrowIA" tabIndex="0" onClick={this.scrollToIA}>
                            <img src="./arrowpurp.svg" alt="purple chevron down" className="arrWire" />
                            <div className="middleWire">
                                <div className="textWire">
                                    see IA
                                </div>
                            </div>
                        </button>
                    </div>

                    <div className="IAFull" id="IAFull">
                        <h2 className="IA2">Information Architecture</h2>
                        <div className="IAEnl">
                            <img src="./IASolace5.png" alt="Solace IA Flow-Chart" className="IA" title="IA"/>
                            <div className="innerText">
                                <div className="textIA">
                                    <a href="https://drive.google.com/file/d/1MXGteVD545RFgLKFKzmYhyR0LAtUFJm1/view?usp=sharing">tap to enlarge</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="arrowIA2" tabIndex="0" onClick={this.scrollToReflec}>
                        <img src="./arrowpurp.svg" alt="purple chevron down"  className="arrRef" />
                        <div className="middleWire">
                            <div className="textWire">
                                see reflection
                            </div>
                        </div>
                    </button>
                </div>
                
                <div className="container7" id="reflec">
                    <div className="reflection">
                        <h1>Reflection</h1>
                        <p>Solace was the first project I'd been a part of in which I'd taken on a leadership role. As Product Lead, I was responsible for developing a vision for Solace, and making sure that progress executed on that vision, and centered the needs of our users. I also conducted user research, competitive analysis, and worked as a bridge between our development and design team, which meant being both a user advocate and a leader who was realistic about team resources. </p>
                        <p>This project taught me about the meaning of balancing user needs and team needs, which meant making hard decisions, revaluating necessary features, and engaging in open dialogue and negotiation with my team. In addition, this role for me was about recognizing the fundamental importance of user research, and that, in difficult times where users cannot be directly accessed, there is still value in drawing from 
                            the research and ideas of our predecessors: from advocates, abuse experts, and the voices of those who had already spoken about their experiences.
                        </p>
                    </div>
                    <div>
                        <img src="./iPhoneXReflection.png" alt="iPhone X mockup of Solace home and onboarding screens" className="reflectionImage" />
                    </div>
                </div>

                <div className="footer">
                    <div className="plink">
                        <div className="nextP">next project</div>
                        <div className="pName">
                            <Link to="/Amplify" title="Amplify">Amplify<img src="./arrow-double.svg"  alt="double arrow right" className="footlink" /></Link>
                        </div>
                    </div>
                </div>
                <div className="bottomLinks">
                    <div className="backTop2">
                        <button title="back to top" onClick={this.scrollToTop} id="backTopB" className="backTopB"><img src="./backTop.svg" alt="back to top arrow" className="backTop" /></button>
                    </div>
                    <div className="email2">
                        <a href="mailto:salem.gordon@gmail.com" title="Email" target="_blank" rel="noopener noreferrer">Email</a> &#8901; <a href="https://linkedin.com/in/salem-gordon" title="LinkedIn" target="_blank" rel="noopener noreferrer">LinkedIn</a> &#8901; <a href="https://drive.google.com/file/d/1OO8cBsaJbeSZPmZB-90QU7HbYqhk1zFn/view?usp=sharing" target="_blank" rel="noopener noreferrer" title="Resume">Resume</a>
                    </div>
                </div>
                <div className="credit">
                    <p>&#169; 2020 Salem Gordon. Made from scratch with React. </p>
                </div>
            </div>
        );
    }
}