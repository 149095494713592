import React from 'react';
import './Solstice.css';
import mountain from './images/mountain.png';
import {Link} from 'react-router-dom';

export default class SolsticePage extends React.Component {

    scrollToTop(event) {
        document.documentElement.scrollTop = 0;
        document.getElementById("dropdown-toggle").style.color = "#7E5DCC";
        document.getElementById("dropdown-toggle").style.fontWeight = "500";
    }

    scrollToTime2(event) {
        document.getElementById("solsc2").scrollIntoView(true);
    }

    scrollToRole2(event) {
        document.getElementById("role2").scrollIntoView(true);
    }

    scrollToFeaS(event) {
        document.getElementById("featuresS").scrollIntoView(true);
    }

    scrollToBg(event) {
        document.getElementById("bgimg").scrollIntoView(true);
    }

    scrollToFoot(event) {
        document.getElementById("solsFoot").scrollIntoView(true);
    }

    scrollFunction2(event) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 50 ) {
            document.getElementById("backTopS").style.display = "block";
        } else {
            document.getElementById("backTopS").style.display= "none";
        }
    }

    changeColor3(event) {
        document.getElementById("dropdown-toggle").style.color = "#000000";
        if (document.documentElement.clientWidth > 767 || document.documentElement.clientWidth === 768) {
            document.getElementById("dropdown-toggle").style.fontWeight = "400";
        } else if (document.documentElement.clientWidth < 767) {
            document.getElementById("dropdown-toggle").style.fontWeight = "500";
        }    
    }


    render() {
        return (
            <div className="solsticePage" onLoad={this.scrollToTop} onScroll={this.scrollFunction2}>
            <div className="Solscontainer" id="titleSection">
                <div className="titleContent3">
                    <div className="centered3">
                        <div className="titleSols">
                            <h1>Solstice</h1>
                            <img src="./SolsticeLogo2.svg" alt="Solstice logo" className="logo2" />
                        </div>
                        <h2>
                            A mobile Operating System designed to promote balance and healthy interactions with the device. Includes style guidelines, applications, and 
                            home and lock screens that encourage mindful phone use.
                        </h2>
                    </div>
                    <img src="./SolsticeLock.png" alt="Solstice home screen with bezel" className="solsPic1" />
                    <img src="./SolsticeLock.png" alt="Solstice home screen with bezel" className="solsPic2" />
                    <picture>
                        <source srcSet="./plain.png" media="(max-width: 768px)" />
                        <source srcSet="./SolsticeLock.png" alt="Solstice home screen with bezel" className="solsPic" />
                        <img src="./SolsticeLock.png" alt="Solstice home screen with bezel" className="solsPic" />
                    </picture>
                </div>
                <button className="arrow2S" onClick={this.scrollToTime2}>
                   <img src="./solsArrow.svg" alt="chevron down" className="arr2S" />
                    <div className="middlethree">
                        <div className="text3">
                            see more
                        </div>
                    </div>
                </button>
            </div>

            <div className="solscontainer2" id="solsc2">
                <div className="larger3">
                    <div className="solstimeline">
                        <div>
                            <h4>TIMELINE</h4>
                            <h5>11 weeks</h5>
                        </div>
                        <div className="solsrole">
                            <h4>ROLE</h4>
                            <h5>UX Designer</h5>
                        </div>
                    </div>
                    <div className="solsaffil">
                        <div className="solsaff">
                            <h4>AFFILIATION</h4>
                            <h5>Project through UW INFO 365 course</h5>
                        </div>
                        <div>
                            <h4>STATUS</h4>
                            <h5><a href="https://drive.google.com/file/d/16-KXzz4LI19YwYIvfFnq6SGwc5sKyi5F/view?usp=sharing" title="Solstice Presentation Slide Deck" target="_blank" rel="noopener noreferrer">Complete</a></h5>
                        </div>
                    </div>
                </div>
                <button className="arrow3S" onClick={this.scrollToRole2}>
                    <img src="./solsArrow.svg" alt="chevron down" className="arr3S" />
                    <div className="middlethree">
                        <div className="text3">
                            see more
                        </div>
                    </div>
                </button>
            </div>

            <div className="solsroleFull" id="role2">
                <h1>Overview</h1>
                <div className="largeConcS">
                    <div>
                        <p className="firstP">
                            In this eleven week course, I designed screens for 7 different applications in a team of eight other designers, in order to design a full Operating System. This course involved weekly design sprint presentations, where we recieved feedback on our 
                            progress, screens, and designs -- and iterated from there. The seven applications that we designed included Weather, Mail, Photos, Media, Music, Messages, Notes, in addition to both Lock and Home screens. 
                        </p>
                        <p>
                            In addition to designing screens themselves, we also conducted research and identified global design components and standards that tied Solstice together. This required us to identify a design language, 
                            UX/UI guidelines, and work to make those consistent across apps, screens, and the OS as a whole. This project was quite iterative, and allowed me to experience the flow of the design process in a team-based environment. 
                        </p>
                    </div>
                    <div>
                        <p className="firstP">
                            For our design language and OS, we decided on the topic of mindfulness, and focused on designing away from the addictive dark patterns employed by many existing applications, and instead 
                            promote healthy and intentional device interaction. We named the OS Solstice to emphasize the feeling of balance, in which the users can find a system that is receptive to their needs, and works 
                            on both the longest and shortest days of the year. 
                        </p>
                        <p>Even more specifically than mindfulness, our research allowed us to narrow the scope to trying to address cognitive or information overload, as well as minimizing distractive aspects of existing Operating Systems. 
                            Although we identified that media addiction played a role in this as well, our time and resource constraints caused us to reassess our focus. 
                        </p>
                    </div>
                </div>
                <button className="arrow4S" onClick={this.scrollToFeaS}>
                    <img src="./solsArrow.svg" alt="chevron down" className="arr4S" />
                    <div className="middlethree">
                        <div className="text3">
                            see more
                        </div>
                    </div>
                </button>
            </div>

            <div className="researchS" id="researchS">
                <h1>Research</h1>

                <div className="interviews">
                    <h2>Interviews &#38; Research</h2>
                </div>
                <div className="findings" id="findings">
                    <h2>Findings</h2>
                    <p>Based on our research, we initially recognized four overarching pain points or problem areas that Solstice should address. We ended up narrowing 
                        our focus to Cognitive Overload and Distraction.
                    </p>
                    <li>1. <b>Cognitive Overload</b></li>
                    <li>2. <b>Distraction</b></li>
                    <li>3. Addiction</li>
                    <li>4. ???</li>
                </div>
            </div>

            <div className="featuresS" id="featuresS">
                <h1>Final Screens</h1>
                <div className="lockScreen">
                    <h2 className="lockTitle">Lock Screen</h2>
                    <div className="lockImg">
                        <img className="lock1" src="./Lock1.png" alt="Solstice Lock Screen" />
                        <img className="lock1" src="./Lock2.png" alt="Solstice Lock Screen" />
                        <img className="lock1" src="./Lock3.png" alt="Solstice Lock Screen" />
                        <img className="lock1" src="./Lock4.png" alt="Solstice Lock Screen" />
                    </div>
                </div>
                <div className="homeScreen">
                    <h2>Home Screen</h2>
                    <div className="lockImg">
                        <img className="lock1" src="./Home1S.png" alt="Solstice Home Screen" />
                        <img className="lock1" src="./Home2S.png" alt="Solstice Home Screen" />
                        <img className="lock1" src="./Home3S.png" alt="Solstice Home Screen" />
                        <img className="lock1" src="./Home4S.png" alt="Solstice Home Screen" />
                    </div>
                </div>
                <div className="photosScreen">
                    <div className="photosTitle">
                        <h2>Photos</h2>
                        <img className="star" src="./star.png" alt="star icon" title="Screens I worked on individually" />
                    </div>
                    <div className="lockImg">
                        <img className="lock1" src="./Album1.png" alt="Albums Home" />
                        <img className="lock1" src="./Album2.png" alt="Solstice Lock Screen" />
                        <img className="lock1" src="./Album3.png" alt="Solstice Lock Screen" />
                        <img className="lock1" src="./Album4.png" alt="Solstice Lock Screen" />
                    </div>
                </div>
                <div className="messageScreen">
                    <h2>Messages</h2>
                    <div className="lockImg">
                        <img className="lock1" src="./Message1.png" alt="Albums Home" />
                        <img className="lock1" src="./Message2.png" alt="Solstice Lock Screen" />
                        <img className="lock1" src="./Message3.png" alt="Solstice Lock Screen" />
                        <img className="lock1" src="./Message4.png" alt="Solstice Lock Screen" />
                    </div>
                </div>
                <div className="weatherScreen">
                    <h2>Weather</h2>
                    <div className="lockImg">
                        <img className="lock1" src="./Weather2.png" alt="Weather Home" />
                        <img className="lock1" src="./Weather1.png" alt="Weather Screen 2" />
                        <img className="lock1" src="./Weather3.png" alt="weather Screen 3" />
                    </div>
                </div>
                <button className="arrow6S" onClick={this.scrollToBg}>
                    <img src="./solsArrow.svg" alt="chevron down" className="arr6S" />
                    <div className="middlethree">
                        <div className="text3">
                            see more
                        </div>
                    </div>
                </button>

            </div>

            <div className="productS" id="productS">
                <h1>Final Deliverables</h1>
                <p>Presentation</p>
                <p>Home &#38; Lockscreen</p>
                <p>Apps</p>
            </div>

            <div className="reflectionS" id="reflectionS">
                <h1>Reflection</h1>
            </div>
           
            <div className="bgimg" id="bgimg" style= {{
                backgroundImage: 'url('+mountain+')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                color: '#333333'}}>
                <div className="left">
                    <div className="box">
                        <p>This page is still under construction!</p>
                        <p>To find out more about this project, please reach out to me through <a href="mailto:salem.gordon@gmail.com" title="Email" target="_blank" rel="noopener noreferrer">email</a> or on <a href="https://linkedin.com/in/salem-gordon" title="Linkedin" target="_blank" rel="noopener noreferrer">Linkedin!</a></p>
                    </div>
                </div>
                <button className="arrow5S" onClick={this.scrollToFoot}>
                   <img src="./solsArrow.svg" alt="chevron down" className="arr5S" />
                    <div className="middlethree">
                        <div className="text5S">see more</div>
                    </div>
                </button>
            </div>
            
            <div className="solsFooter" id="solsFoot">
                <div className="plink3">
                    <div className="nextP3">next page</div>
                    <div className="pName3">
                        <Link to="/About" title="About" onClick={this.changeColor3}>About<img src="./arrow-double.svg" alt="double arrow right" className="footlink3" /></Link>
                    </div>
                </div>
            </div>
            <div className="bottomLinks3">
                <div className="backTop4">
                    <button title="back to top" onClick={this.scrollToTop} id="backTopS" className="backTopS"><img src="./backTop.svg" alt="back to top arrow" className="backTop5" /></button>
                </div>
                <div className="email4">
                    <a href="mailto:salem.gordon@gmail.com" title="Email" target="_blank" rel="noopener noreferrer">Email</a> &#8901; <a href="https://linkedin.com/in/salem-gordon" title="LinkedIn" target="_blank" rel="noopener noreferrer">LinkedIn</a> &#8901; <a href="https://drive.google.com/file/d/1OO8cBsaJbeSZPmZB-90QU7HbYqhk1zFn/view?usp=sharing" title="Resume" target="_blank" rel="noopener noreferrer">Resume</a>
                </div>
            </div>
            <div className="credit4">
                <p>&#169; 2020 Salem Gordon. Made from scratch with React. </p>
            </div>
        </div>   
        );
    }
}