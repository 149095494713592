import React from 'react';
import './App.css';

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footFull">
                <div className="foot">
                    <div className="email"><a href="mailto:salem.gordon@gmail.com" title="Email">Email</a> &#8901; <a href="https://linkedin.com/in/salem-gordon" title="LinkedIn">LinkedIn</a> &#8901; <a href="https://drive.google.com/file/d/1OO8cBsaJbeSZPmZB-90QU7HbYqhk1zFn/view?usp=sharing" title="Resume">Resume</a></div> 
                </div>
                <div className="credit2">
                    <p>&#169; 2020 Salem Gordon. Made from scratch with React. </p>
                </div>
            </div>
        );
    }
}