import React from 'react';
import './App.css';
import { NavLink } from 'react-router-dom';

export default class Nav extends React.Component {

    addTab(event) {
        if (event.which === 13) {
            document.getElementById("dropdown-toggle").toggleAttribute("aria-expanded");
        } 
        if (event.which === 40) {
            document.getElementById("dropdown-toggle").setAttribute("aria-expanded", true);
            document.getElementById("dropdown nav-item").classList.add("open");
            document.getElementById("dropdown-item first").focus();
            document.getElementById("dropdown-toggle").style.color = "#7E5DDC";
            document.getElementById("dropdown-toggle").style.fontWeight = "500";
        } 
    }

    addTabH(event) {
        if (event.which === 39) {
            document.getElementById("dropdown-toggle").focus();
        } else if (event.which === 13) {
            document.getElementById("home2").click();
        }
    }

    addTab1(event) {
        if (event.which === 40) {
            document.getElementById("dropdown-item first2").focus();
        } else if (event.which === 13) {
            document.getElementById("dropdown-item first").click();
        } 
    }

    addTab2(event) {
        if (event.which === 40) {
            document.getElementById("dropdown-item first3").focus();
        }
        if (event.which === 13) {
            document.getElementById("dropdown-item first2").click();
        }
        if (event.which === 38) {
            document.getElementById("dropdown-item first").focus();
        }
    }

    addTab3(event) {
        if (event.which === 40) {
            document.getElementById("resume2").focus();
            document.getElementById("resume").focus();
            document.getElementById("dropdown-toggle").style.color = "#000000";
            document.getElementById("dropdown-toggle").style.fontWeight = "400";
        } 
        if (event.which === 13) {
            document.getElementById("dropdown-item first3").click();
        } 
        if (event.which === 38) {
            document.getElementById("dropdown-item first2").focus();
        }
    }

    addTabD(event) {
        if (event.which === 40) {
            document.getElementById("resume2").focus();
            document.getElementById("resume").focus();
        }
        if (event.which === 13) {
            document.getElementById("dropdown-item first4").click();
        } 
        if (event.which === 38) {
            document.getElementById("dropdown-item first3").focus();
        }
    }

    addTab4(event) {
        if (event.which === 13) {
            document.getElementById("about2").click();
        }
    }

    addTab5(event) {
        if (event.which === 39) {
            document.getElementById("about2").focus();
            document.getElementById("about").focus();
        } 
        if (event.which === 13) {
            document.getElementById("resume2").click();
        }
    }

    onFocus2(event) {
        document.getElementById("dropdown-toggle").setAttribute("aria-expanded", false);
        document.getElementById("dropdown nav-item").classList.remove("open");
        document.getElementById("resume2").focus();
        document.getElementById("dropdown-toggle").style.color = "#000000";
        document.getElementById("dropdown-toggle").style.fontWeight = "400";
    }

    onFocus3(event) {
        document.getElementById("about2").focus();
    }

    onFocus1(event) {
        document.getElementById("dropdown-toggle").style.color = "#000000";
        document.getElementById("dropdown-toggle").style.fontWeight = "400";
        document.getElementById("dropdown nav-item").classList.remove("open");
    }
 
    onCollapse(event) {
        if (document.getElementById("collapseMen").getAttribute("aria-expanded") === false) {
            document.getElementById("myNavbar").setAttribute("aria-expanded", false);
        } else if (document.getElementById("collapseMen").getAttribute("aria-expanded") === true) {
            document.getElementById("myNavbar").setAttribute("aria-expanded", true);
        }
    }

    changeColor2(event) {
        document.getElementById("dropdown-toggle").style.color = "#000000";
        document.getElementById("dropdown-toggle").style.fontWeight = "400";
    }

    boldDrop(event) {
        document.getElementById("dropdown-toggle").style.fontWeight = "500";
    }

    unboldDrop(event) {
        if (document.getElementById("dropdown-item first").classList.contains("activeClass")) {

        } else if (document.getElementById("dropdown-item first2").classList.contains("activeClass")) {

        } else if (document.getElementById("dropdown-item first3").classList.contains("activeClass")) {

        } else {
            document.getElementById("dropdown-toggle").style.fontWeight = "400";
        }
    }

    render () {
        return (
            <div>
                <nav className="navbar navbar-inverse" role="navigation">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" id="collapseMen" data-toggle="collapse" aria-controls="#myNavbar" data-target="#myNavbar" aria-label="Menu" aria-expanded="false" onClick={this.onCollapse}>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>                        
                            </button>
                            <NavLink exact to="/" onClick={this.changeColor2} onFocus={this.onFocus1}><div className="navbar-brand name" title="Home">Salem Gordon</div></NavLink>
                        </div>
                        <ul className="nav navbar-nav navbar-right" id="myNavbar">
                            <li className="home" id="home" role="presentation"><NavLink exact to="/" activeClassName="activeClass1" activeStyle={{ fontWeight: "500", color: "#7E5DDC"}} onKeyDown={this.addTabH} id="home2" onClick={this.changeColor2} onFocus={this.onFocus1}>Home</NavLink></li>    
                            <li className="dropdown nav-item" id="dropdown nav-item">
                                <button className="dropdown-toggle dropbtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Work" id="dropdown-toggle" aria-controls="dropdown-menu" onKeyDown={this.addTab} >Work <span className="caret"></span></button>
                                <div className="dropdown-menu" role="menu" id="dropdown-menu" onMouseOver={this.boldDrop} onMouseOut={this.unboldDrop}>
                                    <NavLink to="/Solace" className="first" id="dropdown-item first" tabIndex="-1" activeClassName="activeClass" activeStyle={{ fontWeight: "500", color: "#7E5DDC"}} role="menuitem" onKeyDown={this.addTab1}>Solace</NavLink>
                                    <NavLink to="/Amplify" className="first2" id="dropdown-item first2" tabIndex="-1" activeClassName="activeClass" activeStyle={{ fontWeight: "500", color: "#446992"}} role="menuitem" onKeyDown={this.addTab2}>Amplify</NavLink>
                                    <NavLink to="/Solstice" className="first3" id="dropdown-item first3" tabIndex="-1" activeClassName="activeClass" activeStyle={{ fontWeight: "500", color: "#7E5DDC"}} role="menuitem" onKeyDown={this.addTab3}>Solstice</NavLink>
                                </div>
                            </li>
                            <li className="team" id="resume" role="presentation" onFocus={this.onFocus2}><a href="https://drive.google.com/file/d/1OO8cBsaJbeSZPmZB-90QU7HbYqhk1zFn/view?usp=sharing" target="_blank" rel="noopener noreferrer" onKeyDown={this.addTab5} onClick={this.changeColor2} id="resume2">Resume</a></li>
                            <li className="about" id="about" role="presentation" onFocus={this.onFocus3}><NavLink exact to="/about" activeClassName="activeClass1" activeStyle={{ fontWeight: "500", color: "rgb(126, 93, 220)"}} role="menuitem" id="about2" onKeyDown={this.addTab4} onClick={this.changeColor2}>About</NavLink></li>
                        </ul>
                    </div>
                </nav>      
            </div>
        );
    }
}