import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';

export default class Intro extends React.Component {
    scrollToTop(event) {
        document.documentElement.scrollTop = 0;
    }

    scrollToProject(event) {
        document.getElementById("project").scrollIntoView(true);
    }
    render () {
        return (
            <div className="header"  >
                <div className="border">
                    <div className="titleIntro">
                        <p className="h1">Hi, I'm <Link to="/about" title="About">Salem</Link>!</p>  
                        <img className="lilacLogo" src="./lilacright.png" alt="lilac logo" />
                    </div>
                    <p className="h2">Recent UW Informatics graduate looking for full-time UX Design &#38; Research positions. Searching for opportunities in Seattle and beyond.</p>
                    <p className="h2">I'm passionate about addressing <b>human problems</b> through <b>design solutions</b>.</p>
                </div>
                <div className="social">
                    <a href="mailto:salem.gordon@gmail.com" title="Email"><img className="emailLink" alt="email link on mail icon" src="./mail2.svg" title="Email" /></a>
                    <a href="https://linkedin.com/in/salem-gordon/" title="Linkedin"><img className="linkedin" alt="linkedin icon" src="./linkedin.svg" title="Linkedin" /></a>
                    <a href="https://drive.google.com/file/d/1OO8cBsaJbeSZPmZB-90QU7HbYqhk1zFn/view?usp=sharing" title="Resume" target="_blank" rel="noopener noreferrer"><img className="paper" title="Resume" alt="Resume link on paper icon" src="./paper.svg" /></a>
                </div>
                <button className="arrow" tabIndex="0" onClick={this.scrollToProject}>
                    <img src="./arrowBlack.svg" alt="chevron down" className="arr" />
                    <div className="middle2">
                        <div className="text2">
                            see projects
                        </div>
                    </div>
                </button>
            
            </div>
        );
    }
}